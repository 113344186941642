import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/nadcha/" className="sel">
              {" "}
              &gt; NÁDCHA
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_1206612040.jpg)" }} />
                    </div>
                    <div className="c2">
                      Alergická nádcha – spoznajte ju a predchádzajte jej{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/zabojujte_s_nadchou_rychlo/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)" }} />
                    </div>
                    <div className="c2">Zabojujte s nádchou rýchlo </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/nadcha/" className="quiz_btn" />
            </div>
            <div className="col2">
              <div className="wysiwyg">
                <div>
                  <img
                    alt="OLYNTH® riešenie a príznaky nádchy"
                    src="/web/kcfinder/uploads/images/sk/Nadcha_SK.png"
                    style={{
                      width: "216px",
                      height: "66px"
                    }}
                  />
                </div>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <div>Nádcha je ľudový názov pre zápal sliznice nosa.</div>
                  <div>&nbsp;</div>
                  <div>
                    Vo väčšine prípadov (až 90<sup>1</sup> %1) ju spôsobuje vírusová
                    infekcia, menej častou príčinou môžu byť aj{" "}
                    <a href="/slovnik_pojmov/#alergen" target="_blank">
                      alergény
                    </a>
                    &nbsp;alebo baktérie.
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Vysušovanie nosovej sliznice a oslabenie organizmu vedie k
                    prieniku vírusov a vzniku zápalového stavu. Vniknutie
                    škodlivých vonkajších činiteľov do tela spúšťa obrannú
                    reakciu nášho{" "}
                    <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                      imunitného systému
                    </a>
                    .
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Spočiatku sa objavuje vodnatý výtok, ktorý s postupom
                    infekcie hustne. Krvné cievky nachádzajúce sa v nosovej
                    sliznici sa rozširujú, čo spôsobuje opuch, ktorý vyvoláva
                    pocit upchatého nosa a problémy s dýchaním nosom.&nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Pri nádche je veľmi dôležité správne dbať o priechodnosť
                    nosa a jeho zvlhčovanie. Nos pomôže uvoľniť, ak z neho
                    odstránime prebytočné hlieny, a tým aj vírusy, ktoré
                    obsahuje. Priechodné dýchacie cesty zaistia voľné dýchanie
                    počas spánku, ktorý je pre regeneráciu organizmu zásadný.
                    Hydratovaná nosová sliznica prispieva k vyššiemu komfortu
                    dýchania počas nádchy a znižuje riziko prieniku vírusov do
                    organizmu.
                  </div>
                  <div>&nbsp;</div>
                </div>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <sup>1</sup>1Dr Joanna Lange, Honorata Marczak, Infekcje
                    dróg oddechowych u dzieci, portal Medycyna praktyczna;
                    http://pediatria.mp.pl/choroby/ukladoddechowy/137909,infekcje-drog-oddechowych-u-dzieci
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="drugs">
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_skupinka_01_SK_LEFT_2019.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> 0,1%<br/>
                      nosová roztoková<br/>
                      aerodisperzia
                    </p>
                    <div className="desc">RÝCHLA ÚĽAVA OD PRÍZNAKOV NÁDCHY</div>
                    <a href="/lieky/olynth_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_skupinka_HA_01_SK_LEFT.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> HA 0,1%<br/>
                      nosová roztoková<br/>
                      aerodisperzia
                    </p>
                    <div className="desc">
                    RÝCHLA ÚĽAVA OD  NÁDCHY. ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA TROM ZVLHČUJÚCIM LÁTKAM.
                    </div>
                    <a href="/lieky/olynth_ha_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/olynth_adult.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS <br />1 mg/50 mg/ml nosová roztoková aerodisperzia
                    </p>
                    <div className="desc">
                    RÝCHLO UVOĽŇUJE UPCHATÝ NOS. URÝCHĽUJE HOJENIE PORANENEJ NOSOVEJ SLIZNICE.
                    </div>
                    <a href="/lieky/olynth_plus_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
